// import axios from "axios";
import React, {
  useEffect,
  useImperativeHandle,
  forwardRef,
  useState,
  useContext, useRef
} from "react";
import MyContext from "./../../MyContext";
import LoginApi from "../../../API/API_SERVISES/Login";
import { useNavigate } from "react-router-dom";
import OTPinfo from "../../../API/API_SERVISES/OTPinfo";
import Account from "../../../API/API_SERVISES/Account";
import ResetPasswordPanel from "../../Component/Modal/ResetPasswordPanel";
import GetOTPPanel from "../../Component/Modal/GetOTPPanel";
import SignInPanel from "../../Component/Modal/SignInPanel";
import Loader1 from "../Loaders/Loader1";
import ForgotPasswordPanel from "../Modal/ForgotPasswordPanel";
import LoginPanel_Lc from "../Modal/LoginPanel_Lc";
import Order from "../../../API/API_SERVISES/Order";

const MyAccount = forwardRef((props, ref) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const {
    loginPanel,
    setLoginShow,
    signin,
    signupshow,
    signupshow1,
    getOtp,
    GoToOTP,
    forgotPassword,
    forgot,
    ResetPassword,
    ResetPasswordUI,
  } = useContext(MyContext);

  const { close } = props

  const handleEscapeKey = () => {
    props.close();
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [visibleResendOTP, setvisibleResendOTP] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(true);
  };
  const handleTogglePasswordhide = () => {
    setShowPassword(false);
  };

  const handleTogglePassword1 = () => {
    setShowPassword1(true);
  };
  const handleTogglePasswordhide1 = () => {
    setShowPassword1(false);
  };

  const [allAccountData, setAllAccountData] = useState({});

  useEffect(() => {
    console.log(allAccountData);
  }, [allAccountData]);

  const GetAllAccount = async () => {
    const AllAccount = await Account.getAccount();
    setAllAccountData(AllAccount);
  };
  useEffect(() => {
    GetAllAccount();
  }, [])

  const [inputValuesForLogin, setInputValuesForLogin] = useState({
    name_email: "",
    password: "",
  });

  const [inputValuesForSignIn, setInputValuesForSignIn] = useState({
    name: "",
    aEmail: "",
    aMobileNo: "",
    password: "",
    reEnterpassword: "",
    namePrefix: "",
    printName: "",
    alias: "",
    accountType: 0,
    parentGrp: 0,
    aState: -1,
    aStation: -1,
    aDefaultSalePerson: -1,
    adob: "",
    aAge: 0,
    adom: "",
    aGender: "",
    aDepartment: 0,
    aDesignation: 0,
    aDrugLicenseNo: "",
    aOpeningBal: 0,
    aOpeningCrDr: "",
    aContactperson: "",
    contactpersonMbl: "",
    aCreditlimit: 0,
    aCreditperiod: 0,
    aAccountCategory: -1,
    aEnableLoyalty: false,
    aLoyaltyCardNo: "",
    referralBy: -1,
    assignUser: -1,
    aAddress: "",
    aEnableEmail: "",
    aPincode: "",
    aLocation: "",
    aDistance: "",
    accountDealerType: "",
    agStINNo: "",
    aitPan: "",
    alstNo: "",
    acstNo: "",
    aAadharNo: "",
    doNotDisturb: "",
    accountDetails: "",
    aDefaultTranspoter: -1,
    openingCrDr: "",
    system_defined: false,
    createdBy: "Self",
    createdOn: "",
    isActive: true,
    accOptFieldValues: [
      {
        optionalFieldName: "",
        optionalFieldValue: "",
        isDeleted: false,
        isActive: true,
      },
    ],
  });

  const [inputValuesForOTP, setInputValuesForOTP] = useState(['', '', '', '']);;

  const [inputValuesForForgotPassword, setInputValuesForForgotPassword] =
    useState({
      otp: "",
    });


  const handleEnterKey = () => {
    LoginAccount();
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case "Escape":
          handleEscapeKey();
          break;
        case "Enter":
          handleEnterKey();
          break;
        default:
          break;
      }
    };

    // Add event listener when the component mounts
    document.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []); // Empty dependency array ensures the effect runs only once when the component mounts



  const [checkOTP, setCheckOtp] = useState(false);

  const resendOTP = () => {
    alert("resend otp");
  };

  const SendOtp = async () => {
    if (
      inputValuesForSignIn.password === inputValuesForSignIn.reEnterpassword

    ) {
      setLoading(true);
      var sendotp = await OTPinfo.SendOtp(inputValuesForSignIn);
      if (sendotp.result === "Account already exists.") {
        alert(sendotp.result);
        signupshow();
        setInputValuesForSignIn("");
      } else {
        GoToOTP();
        setIsDisabled(true);
        setvisibleResendOTP(true);
        setSecondsLeft(60);
      }
      setLoading(false);
    } else {
      alert("Password and Re-enter password does not match");
    }
  };

  const [isDisabled, setIsDisabled] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(0);

  // This function is called when the button is clicked
  const handleClick = () => {
    setIsDisabled(true); // Disable the button
    setSecondsLeft(60); // Start countdown from 60 seconds
    SendOtpForForgotPassword();
  };


  useEffect(() => {
    let interval = null;

    if (isDisabled && secondsLeft > 0) {
      // Update the countdown every second
      interval = setInterval(() => {
        setSecondsLeft(secondsLeft - 1);
      }, 1000);
    } else if (secondsLeft === 0) {
      // Re-enable the button when countdown reaches zero
      setIsDisabled(false);
    }

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [isDisabled, secondsLeft]);

  const [loading, setLoading] = useState(false);

  const SendOtpForForgotPassword = async () => {
    if (isDisabled === false) {
      setLoading(true);
      var sendOtpForforgot = await OTPinfo.SendOtpForForgotPassword(
        inputValuesForSignIn
      );
      if (sendOtpForforgot) {
        setvisibleResendOTP(true);
      }

      setIsDisabled(true); // Disable the button
      setSecondsLeft(60);
      setLoading(false);
    }
  };

  const VerifyOTP = async () => {
    const combinedString = inputValuesForOTP.join("");
    console.log(combinedString);
    const VerifyOtp = await OTPinfo.VerifyOtp(
      inputValuesForSignIn,
      combinedString
    );
    console.log(VerifyOtp);
    if (VerifyOtp === "OTP failed to verify! Incorrect OTP!") {
      alert(VerifyOtp);
    } else {
      ResetPasswordUI();
    }
  };

  const handleInputChangeLogin = (event) => {
    const { name, value } = event.target;
    // console.log(`Changing ${name} to ${value}`);
    setInputValuesForLogin((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleInputChangeSignIn = (event) => {
    const { name, value } = event.target;
    // console.log(`Changing ${name} to ${value}`);
    setInputValuesForSignIn((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const navigate = useNavigate();

  const LoginAccount = async () => {

    const Login = await LoginApi.LogInAccount(inputValuesForLogin);
    if (Login.isSuccess === true) {
      props.close();
      await Order.UpdateClientIdByAccountId(Login.result)
      localStorage.setItem("accountLogin", JSON.stringify(Login.result));
      alert(`Welcome ${Login.result.name}`);
      window.location.reload();
      setLoginShow(false);
      // navigate("/MyAccountPage");
    } else {
      alert("Wrong Id and Password");
    }
  };
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];
  const handleInputChangeOTP = (index, value) => {
    const newOTP = [...inputValuesForOTP];
    newOTP[index] = value;

    setInputValuesForOTP(newOTP);
    if (value !== "") {
      const nextIndex = index < 3 ? index + 1 : index;
      inputRefs[nextIndex].current.focus();
    }
  };

  const SignInAccount = async () => {
    const combinedString = inputValuesForOTP.join("");
    const VerifyOtp = await OTPinfo.VerifyOtp(
      inputValuesForSignIn,
      combinedString
    );
    console.log(VerifyOtp);
    //// debugger;
    alert(VerifyOtp);
    if (VerifyOtp !== "OTP failed to verify! Incorrect OTP!") {
      const signin = await LoginApi.insertAccount(inputValuesForSignIn);
      //// debugger
      if (signin) {
        const Login = await LoginApi.AutoLogInAccount(inputValuesForSignIn);
        if (Login) {
          props.close();
          localStorage.setItem("accountLogin", JSON.stringify(Login.result));
          alert(`Welcome ${Login.result.name}`);
          setLoginShow(false);
          // navigate("/MyAccountPage");
        } else {
          alert("Wrong Id and Password");
        }
        props.close()
      } else {
        alert("Account Already Exist");
      }
    }
  };

  const openResetpassword = () => {
    ResetPasswordUI()
  }


  const ChangePassword = async () => {

    if (inputValuesForSignIn.password === inputValuesForSignIn.reEnterpassword) {
      //// debugger
      var checkType = ""
      if (inputValuesForSignIn.aEmail.includes('@')) {
        checkType = "Email"
      }
      else if (/^[\d\s-()+]+$/.test(inputValuesForSignIn.aEmail)) {
        checkType = "MobileNo"
      }

      let requestData = {};

      if (checkType === "Email") {
        requestData = [{
          "fieldName": "email",
          "operatorName": "equal",
          "compareValue": inputValuesForSignIn.aEmail
        }];
      } else if (checkType === "MobileNo") {
        requestData = [{
          "fieldName": "mobileNo",
          "operatorName": "equal",
          "compareValue": inputValuesForSignIn.aEmail
        }];
      }
      const accountData = await Account.getAccountWithFilter(requestData)


      if (accountData.length > 0) {
        var PasswordUpdate = [
          {
            "operationType": 0,
            "path": "Password",
            "op": "add",
            "from": "string",
            "value": inputValuesForSignIn.password
          }
        ]

        var ChangePassword = await Account.PartiallyUpdateAccountForOTP(
          PasswordUpdate,
          accountData
        );
        if (ChangePassword) {
          alert("Your Password Updated Successfully")
          signupshow()
        }
        else {
          alert("Something Went Wrong")
        }
      }
    }
    else {
      alert("Password and Re-enter password does not match")
    }
  }
  useImperativeHandle(ref, () => ({}));


  return (
    <div className="modal-back">


      {loginPanel && (
        <LoginPanel_Lc
          handleClick={signin}
          signupshow1={signupshow1}
          LoginAccount={LoginAccount}
          forgotPassword={forgotPassword}
          inputValuesForLogin={inputValuesForLogin}
          handleInputChangeLogin={handleInputChangeLogin}
          close={close}
        />
      )}

      {signin && (
        <SignInPanel
          showPassword1={showPassword1}
          handleInputChangeSignIn={handleInputChangeSignIn}
          inputValuesForSignIn={inputValuesForSignIn}
          signupshow={signupshow}
          SendOtp={SendOtp}
          close={close}
          handleTogglePasswordhide={handleTogglePasswordhide}
          showPassword={showPassword}
          handleTogglePasswordhide1={handleTogglePasswordhide1}
          handleTogglePassword={handleTogglePassword}
          handleTogglePassword1={handleTogglePassword1}
        />
      )}

      {getOtp && (
        <GetOTPPanel
          SignInAccount={SignInAccount}
          secondsLeft={secondsLeft}
          isDisabled={isDisabled}
          handleClick={handleClick}
          visibleResendOTP={visibleResendOTP}
          inputValuesForOTP={inputValuesForOTP}
          handleInputChangeOTP={handleInputChangeOTP}
          close={close}
          inputRefs={inputRefs}
        />
      )}

      {forgot && (
        <ForgotPasswordPanel
          handleInputChangeSignIn={handleInputChangeSignIn}
          inputValuesForSignIn={inputValuesForSignIn}
          VerifyOTP={VerifyOTP}
          inputValuesForOTP={inputValuesForOTP}
          secondsLeft={secondsLeft}
          handleInputChangeOTP={handleInputChangeOTP}
          isDisabled={isDisabled}
          handleClick={handleClick}
          visibleResendOTP={visibleResendOTP}
          SendOtpForForgotPassword={SendOtpForForgotPassword}
          close={close}
          inputRefs={inputRefs}

        />
      )}

      {ResetPassword && (
        <ResetPasswordPanel
          ChangePassword={ChangePassword}
          handleTogglePassword={handleTogglePassword}
          handleTogglePasswordhide={handleTogglePasswordhide}
          showPassword={showPassword}
          handleTogglePasswordhide1={handleTogglePasswordhide1}
          handleTogglePassword1={handleTogglePassword1}
          inputValuesForSignIn={inputValuesForSignIn}
          showPassword1={showPassword1}
          handleInputChangeSignIn={handleInputChangeSignIn}
          close={close}
        />
      )}
      {loading && <Loader1 />}
    </div>

  );
});

export default MyAccount;
