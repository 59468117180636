// import React from 'react'
// import SocialMediaLinks from '../SocialMediaBox/SocialMediLink'
// import SocialMediaLink3 from '../SocialMediaBox/SocialMediaLink3'
// import SocialMediaLink2 from '../SocialMediaBox/SocialMediaLink2'

// const Footer1 = () => {
//   return (
//     <div className="footer">
//       {/* <div className="footer-top">footer top</div> */}
//       <div className="footer-top">
//         <div className="footer-top-part">
//           <h1 className="footer-text-header">Help</h1>
//           <div>
//             <p className="footer-text-link">Terms & Conditions</p>
//             <p className="footer-text-link">Cancellation Policy</p>
//             <p className="footer-text-link">Return Policy</p>
//             <p className="footer-text-link">Refund Policy</p>
//             <p className="footer-text-link">Replacement Policy</p>
//             <p className="footer-text-link">Delivery / Shipping Policy</p>
//             <p className="footer-text-link">Contact Us</p>
//             <p className="footer-text-link">Download App</p>
//           </div>
//         </div>
//         <div className="footer-top-part">
//           <h1 className="footer-text-header">Links</h1>
//           <div>
//             <p className="footer-text-link">Gadgets</p>
//             <p className="footer-text-link">Drones</p>
//             <p className="footer-text-link">Mobile Phones</p>

//           </div>
//         </div>
//         <div className="footer-top-part">
//           <h1 className="footer-text-header">Feedback</h1>
//           <div>
//             <p className="footer-text-link">Homepage</p>
//             <p className="footer-text-link">Our Menu</p>
//             <p className="footer-text-link">My Favourite</p>
//             <p className="footer-text-link"> My Cart</p>
//             <p className="footer-text-link">My Account</p>
//           </div>
//         </div>
//         <div className="footer-top-part media-links">

//           {/* <SocialMediaLinks /> */}
//           {/* <SocialMediaLink3 /> */}
//           <SocialMediaLink2 />
//         </div>
//       </div>
//       <div className="footer-bottom">
//         Copyright © 2023 Mother,s Hut | All rights reserved. Developed by
//         Techcherry
//       </div>
//     </div>
//   )
// }

// export default Footer1



import React, { useEffect, useState } from "react";
import WebTemplate from "../../../API/API_SERVISES/WebTemplate";
import { useParams } from "react-router-dom";
// import styles from "../Css/HomePage.Module.css"
// import styles1 from "../Css/Edit.Module.css"
// import styles2 from "../Css/Draggable.Module.css"



const Footer = (props) => {
  const [apiData, setApiData] = useState([]);
  const [droppedItems, setDroppedItems] = useState([]);
  const [styleArray, setStylesArray] = useState([]);



  useEffect(() => {
    console.log("styleArray", styleArray);
    console.log("apiData", apiData);
    console.log("droppedItems", droppedItems);
  }, [apiData, droppedItems, styleArray]);

  const showTemplateData = async (e) => {
    const filteredData = [{
      "fieldName": "name",
      "operatorName": "equal",
      "compareValue": "Footer"
    }];
    var getdata = await WebTemplate.GetCustomizedPageTemplatesWithFilter(filteredData);
    // debugger
    if (getdata) {
      if (getdata.jsonStructure) {
        try {
          const decodedData = decodeURIComponent(getdata.jsonStructure)
          const parsedData = JSON.parse(decodedData);
          // getdata.jsonStructure = parsedData
          setDroppedItems(parsedData);
          if (getdata.textContents) {
            const decodedData = decodeURIComponent(getdata.textContents)
            const parsedData = JSON.parse(decodedData);
            // getdata.jsonStructure = parsedData
            setStylesArray(parsedData);
          }
        } catch (error) {
          console.error('Error parsing JSON:', error);
          setDroppedItems([]);
        }
      } else {
        setDroppedItems([]);
      }
      var templateInnerHtml = getdata.textContents;
      var templateInnerHtmlDecode = decodeURIComponent(templateInnerHtml);
      getdata.textContents = templateInnerHtmlDecode

      setApiData(getdata);

      // setDataHtml(getdata)

    } else {
      // document.getElementById("drop-target-innerHtml").outerHTML = "";
      setDroppedItems([]);
    }
  }
  useEffect(() => {
    if (props.currentUser) {
      showTemplateData()
    }
  }, [props.currentUser]);
  useEffect(() => {
    // Debugging
    // debugger;

    // Use a for loop to iterate over stylesArray
    for (let index = 0; index < styleArray.length; index++) {
      const styleObject = styleArray[index];
      console.log(styleObject.id);

      const element = document.getElementById(styleObject.id);

      if (element) {
        for (const [key, value] of Object.entries(styleObject.style)) {
          // Convert kebab-case to camelCase for inline styles
          const camelCaseKey = key.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
          element.style[camelCaseKey] = value;
        }
      }
    }
  }, [styleArray]);


  const renderDroppedItems = () => {
    return droppedItems.length > 0 && droppedItems.some(item => item.items.length > 0) ? (
      droppedItems.map((item, index) => {
        if (item.items.length > 0) {
          return (
            <div key={index} dangerouslySetInnerHTML={{ __html: item.textContents }} />
          );
        }
        return null;
      })
    ) : null;

  };

  return (
    <div style={{ backgroundColor: "#000042" }} >
      {renderDroppedItems()}
    </div>
  )
}


export default Footer;
