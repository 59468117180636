import React, { useContext, useState, useEffect } from 'react';
import MyContext from '../../MyContext';
import "./../../Css/SearchBox.scss";
import { Link, useNavigate } from 'react-router-dom';

const SearchItemBox = (props) => {
    const { setProductList, currentUser, imageBaseUrl, searchRef } = useContext(MyContext);
    const [suggestion, setsuggestion] = useState([]);
    const [selected, setselected] = useState(null);
    const { Data, setClose, close } = props; // Array of search suggestions
    const navigate = useNavigate();
    var isSearch = true;

    useEffect(() => {
        if (currentUser === true) {
            setsuggestion(Data);
        }
    }, [Data, currentUser]);






    // Function to handle key navigation and Enter key
    useEffect(() => {




        const handleKeyDown = (e) => {
            // Handle ArrowDown key
            if (e.key === 'ArrowDown') {
                if (selected === null) {
                    setselected(0);
                    setClose(true)
                    // suggestion.length !== 0 && props.setSearchValue(suggestion[0].name);
                } else {
                    if (selected < suggestion.slice(0, 15).length - 1) {
                        setClose(true)
                        // props.setSearchValue(suggestion[selected + 1].name);
                        setselected(selected + 1);
                    }
                }
            }

            // Handle ArrowUp key
            if (e.key === 'ArrowUp') {
                setClose(true)
                if (selected > 0) {
                    setClose(true)

                    setselected(selected - 1);
                }
            }

            // Handle Enter key
            if (e.key === 'Enter') {
                setClose(true)
                if (selected !== null && suggestion[selected]) {
                    // Navigate to the selected item's page
                    props.setSearchValue(suggestion[selected].name);
                    navigate(`/ProductDetailTheme/${suggestion[selected].d2Id}`, { state: { isSearch } });
                    setClose(false); // Close the suggestion box
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [selected, suggestion, props, navigate]);

    // Set search value when an item is clicked
    function setsearchvalue(item) {
        setClose(false);
        props.setSearchValue(item.name);
    }

    return suggestion && close ? (
        <>
            <div className={`search_box_container`}>
                {suggestion?.slice(0, 15).map((item, index) => {
                    return (
                        <Link
                            key={item.d1Id}
                            style={{ backgroundColor: `${selected === index ? "#ccc" : "white"}` }}
                            to={`/ProductDetailTheme/${item.d2Id}`}
                            state={{ isSearch }}
                            onClick={() => setsearchvalue(item)}
                            className={`every-search-suggestion`}
                        >
                            <div className='suggestion-name-img-box'>
                                <span className='search-suggetion-span'>
                                    <i className="fas fa-search"></i>
                                </span>
                                <p className='search-suggetion-name'>{item.name}</p>
                            </div>
                            {item.images[0] ? (
                                <img style={{ width: "30px", height: "30px", margin: "0px 10px 0px 5px", borderRadius: "8px" }} src={`${imageBaseUrl}${item.images[0]}`} alt="" />
                            ) : (
                                <div style={{ width: "30px", height: "30px", margin: "0px 10px 0px 5px" }}> </div>
                            )}
                        </Link>
                    );
                })}
            </div>
        </>
    ) : (
        <>
            {close === true && <p> Loading...</p>}
        </>
    );
};

export default SearchItemBox;
