import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import MyContext from "../../../MyApp/MyContext";
import Order from "../../../API/API_SERVISES/Order";

const ProductCart1 = (props) => {

  const { imageBaseUrl } = useContext(MyContext);

  const { items, itemList, isVariationItmShowInCatPage } = props
  const [item, setitem] = useState(items);

  const [isHovered, setHovered] = useState(false);
  const { wishList, setWishList} = useContext(MyContext);

  // const [wishList, setWishList] = useState([])

  // const fetchData = async () => {
  //   try {
  //     const data = await Order.getGetAllFavouriteItemList();
  //     console.log(data);
  //     setWishList(data);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // }

  // useEffect(() => {
  //   fetchData();
  // }, [wishList]);

  const [, setInputValues] = useState({
    d1ItemId: item.d1ItemId,
    d2ItemId: item.d2ItemId,
    d1AccountId: 0,
    d2AccountId: 0,
    userId: item.userId,

  });

  const toggleWishlist = async (itemData) => {

    console.log(itemData);
    console.log(item);
    console.log(wishList);
    const existingIndex = wishList.find(product => product.d1ItemId === item.d1Id);
    console.log(existingIndex);
    if (existingIndex !== undefined) {
      // alert("Item is already in wishlist");
      try {
        const val = await Order.deleteDeleteFavouriteItemList(existingIndex.id);
        if (Boolean(val) === true) {
          // alert("Delete data successfully", "Ok");
          // fetchData()
        } else {
          alert("Error Deleting Data");
        }
        // fetchData();
      } catch (error) {
        console.error("Error deleting account:", error);
      }
      return;
    } else {

      const data = {
        d1ItemId: item.d1Id,
        d2ItemId: item.d2Id,
        d1AccountId: 0,
        d2AccountId: 0,
        userId: 1,
      };
      try {
        const isSuccess = await Order.insertCreateFavouriteItemList(data);
        if (isSuccess) {
          // alert("Added to wishlist successfully");
          // fetchData()
        } else {
          throw new Error("Error while adding to wishlist");
        }
      } catch (error) {
        console.error("Error while adding to wishlist:", error);
        alert("Error while adding to wishlist");
      }
      setInputValues({
        d1ItemId: "",
        d2ItemId: "",
        d1AccountId: "",
        d2AccountId: "",
        userId: "",
      });
    };
  }

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const [colorChangefunc, setColorChangeFunc] = useState("color-1");

  const colorchange1 = (event) => {
    setColorChangeFunc(event.target.id);
  };

  return (
    <div style={{ position: "relative" }}>
     <div style={{ cursor: "pointer", position: "absolute", right: "0px", zIndex: "1" }} onClick={() => toggleWishlist(item)}>
    {(wishList && wishList.find(product => product.d1ItemId === item.d1Id)) ? (
        <i className="fa-solid fa-heart HeartSaveBtn2"></i>
    ) : (
        <i className="fa-regular fa-heart HeartSaveBtn2"></i>
    )}
</div>

      <Link to={`/productdetail/${item.d2Id}`} state={{ item }} className="Card-product-type-1">
        {/* <div className="Card-product-type-1"> */}
        {/* <i
        style={{ color: "red", cursor: "pointer" }}
        className="fa-solid fa-heart card-menu-favorite"
      ></i> */}





        {colorChangefunc === "color-1" && (
              <div key={item.id}
            className="Card-product-type-1-top"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <img
              className="Card-product-type-1-img"
              src={
                isHovered
                ? `${imageBaseUrl}${item.images[1]}`
                : `${imageBaseUrl}${item.images[0]}`
              }
              alt=""
            />
          </div>
        )}

        {colorChangefunc === "color-2" && (
          <div
            className="Card-product-type-1-top"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <img
              className="Card-product-type-1-img"
              src={
                isHovered
              ? `${imageBaseUrl}${item.images[3]}`
              : `${imageBaseUrl}${item.images[2]}`
              }
              alt=""
            />
          </div>
        )}

        {colorChangefunc === "color-3" && (
          <div
            className="Card-product-type-1-top"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <img
              className="Card-product-type-1-img"
              src={
                isHovered
              ? `${imageBaseUrl}${item.images[4]}`
              : `${imageBaseUrl}${item.images[3]}`
              }
              alt=""
            />
          </div>
        )}

        {colorChangefunc === "color-4" && (
          <div
            className="Card-product-type-1-top"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <img
              className="Card-product-type-1-img"
              src={
                isHovered
              ? `${imageBaseUrl}${item.images[5]}`
              : `${imageBaseUrl}${item.images[4]}`
              }
              alt=""
            />
          </div>
        )}

        {colorChangefunc === "color-5" && (
          <div
            className="Card-product-type-1-top"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <img
              className="Card-product-type-1-img"
              src={
                isHovered
              ? `${imageBaseUrl}${item.images[6]}`
              : `${imageBaseUrl}${item.images[5]}`
              }
              alt=""
            />
          </div>
        )}

        <div className="Card-product-type-1-middle">
          <div className="Card-product-type-1-rating-color-box">
            <div className="Card-product-type-1-rating-box">
              <div className="card-product-name">
                4.7 <i className="fa-solid fa-star cart-star-c2"></i> {item.mrpPrice}
              </div>
            </div>
            <div className="Card-product-type-1-color-box">
              <div
                onClick={() => colorchange1({ target: { id: "color-1" } })}
                className="outline-coloroption-c2"
              >
                <div className="color-option-box-main color-option-box1-c2"></div>
              </div>
              <div
                onClick={() => colorchange1({ target: { id: "color-2" } })}
                className="outline-coloroption-c2"
              >
                <div className="color-option-box-main color-option-box2-c2"></div>
              </div>
              <div
                onClick={() => colorchange1({ target: { id: "color-3" } })}
                className="outline-coloroption-c2"
              >
                <div className="color-option-box-main color-option-box3-c2"></div>
              </div>
              <div
                onClick={() => colorchange1({ target: { id: "color-4" } })}
                className="outline-coloroption-c2"
              >
                <div className="color-option-box-main color-option-box4-c2"></div>
              </div>
              <div
                onClick={() => colorchange1({ target: { id: "color-5" } })}
                className="outline-coloroption-c2"
              >
                <div className="color-option-box-main color-option-box5-c2"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="Card-product-type-1-bottom">
          <div className="Card-product-type-1-desc-box">
            <div>
              {/* <p className="card-product-name">Black shade glasses</p> */}
              <p className="card-product-name">
                {item.name}
              </p>

            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p className="card-product-price">
                <i className="fa-solid fa-indian-rupee-sign"></i> {item.salePrice}
              </p>
              <p className="card-product-price-2">
                <i className="fa-solid fa-indian-rupee-sign"></i> {item.salePrice * 3}
              </p>
            </div>
            <div>
              <p className="card-product-desc">{item.shortDescription ? item.shortDescription : "No short description found!"}</p>
            </div>
          </div>
        </div>
        <div className="Card-product-type-1-end">
          Get 40% OFF in Coupon : LENSFREE
        </div>


      </Link>
    </div>
  );
};

export default ProductCart1;
